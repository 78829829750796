import './Button.css'


const Button = ({displayText, buttonAction, showButton = true}) => {
    return (
        <div style ={{opacity: showButton ? '1' : '0.3', pointerEvents: showButton ? 'auto' : 'none'}} className="buttonContainer" onClick={buttonAction}>
            <p className="buttonChild">{displayText}</p>
        </div>
    );
}

export default Button;