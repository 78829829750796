import './CurrencyOption.css'
import CurrencyOptionDropdown from './CurrencyOptionDropdown';
const CurrencyOption = ({list, callback}) => {
    
    return (
        <div className="currency-option">
            Select currency you want to pay with.
            <CurrencyOptionDropdown list={list} callback={callback}/>
        </div>
    )
}

export default CurrencyOption;