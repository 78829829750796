import './DEFACharger.css'
import '../../assets/css/Fonts.css'

const DEFACharger = ({csId, imageLogo}) => {
    return (
        <div class="defa-charger">
            <div class="defa-charger-element">
                <img class="defa-charger-image" src={imageLogo} alt="DEFA Power charging station"/>
                <div class="defa-charger-text">
                    <div class="defa-charger-text-header">DEFA Power</div>
                    <div class="defa-charger-text-description">{csId}</div>
                </div>
            </div>
        </div>
    )
}

export default DEFACharger;