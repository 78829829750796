import './TermsAndCond.css'

const TermsAndCond = ({text, link}) => {
    return (
        <div class="text-temp-style">
            {text}<a href={link} target="_blank" rel="noopener noreferrer" style={{color: "white"}}>Terms and Conditions</a>
        </div>
    )
}

export default TermsAndCond;