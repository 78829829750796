import './SerialNumberInput.css'
import '../../assets/css/Fonts.css'
import pic from '../../assets/images/information-circle.svg'

const SerialNumberInput = ({handler}) => {
    const handleInputChange = (event) => {
        handler(event.target.value);
      };
    return (
       <div className='input-container'>
        <div className='text-container'>
            <div className='header-container'>
                <div className='header-text'>DEFA Power Serial Number</div>
                <img className='header-icon' src={pic} alt='Icon'/>    
            </div>
            <div className='header-help-text'>The serial number is located on top of your DEFA Power</div>
        </div>
        <input onChange={handleInputChange} className='input-input' placeholder='E.g. 123A345B678C'>
        </input>
       </div>
    )
}

export default SerialNumberInput;