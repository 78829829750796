import './SerialNumber.css'
import '../../assets/css/Fonts.css'
 
const SerialNumber = ({pic, alt, headerText, bodyText}) => {
    return (
        <div className='serial-number-container'>
        <div class="serial-number">
            <div class="serial-number-element">
                <div className='serial-number-image-container'>            
                    <img src={pic} alt={alt} className="serial-number-image"/>
                </div>
                <div class="serial-number-text">
                    <div class="serial-number-text-header">
                        {headerText}
                    </div>
                    <div class="serial-number-text-description">
                        {bodyText}
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default SerialNumber;