import './DEFAApp.css';
import Main from './components/Main'
import {Routes, Route, Navigate} from 'react-router-dom';
import Coverage from './components/Coverage/Coverage';
function App() {
    return (
        <div class="defa-body">
            <div class="container">
                <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                    <div class="defa-elements">
                    <Routes>
                        <Route path='/' Component={Main} />
                        <Route path='/power/:serialNumber' Component={Coverage} />
                        <Route path='/power/:serialNumber/success' Component={Coverage} />
                        <Route path='/power/:serialNumber/cancel' Component={Coverage} />
                        <Route path='*' element={<Navigate to="/" replace />}/>
                    </Routes>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
