import { useState, useEffect } from "react";

const CurrencyOptionDropdown = ({list, callback}) => {
    const [selected, setSelected] = useState("")
    const [isOpen, setIsOpen] = useState(false);
  
    const selectOption = (option) => {
        setSelected(option.currency.toUpperCase());
        callback(option);
        setIsOpen(false);
      };   

    useEffect(() => {
        const defaultPrice = list.find(x => x.defaultPrice);
        selectOption(defaultPrice);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
      };

    const options = list.sort((a,b) => a.currency.localeCompare(b.currency)).map(x => <div className="dropdown-elem" onClick={() => selectOption(x)}>{x.currency.toUpperCase()}</div>);

    return (
        <div className="dropdown">
        <div className="dropdown-toggle" onClick={toggleDropdown}>
          {selected ? <p style={{ marginRight:'10px', marginLeft:'20px'}}>{selected}</p> : <p>'Select an option'</p>}
          <span className={`dropdown-arrow ${isOpen ? 'open' : ''}`}></span>
        </div>
        {isOpen && (
        <div className="dropdown-options">
          {options}
        </div>
      )}
    </div>
    );
}

export default CurrencyOptionDropdown;