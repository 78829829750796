import './UserNavInfo.css'

const UserNavInfo = ({navigation}) => {
    return (
        <div className='usernavinfo-text'>
            {navigation ? <p className='usernavinfo-text-lower'>You can close this page now.</p> : <></>}
        </div>
    )
}

export default UserNavInfo;