import './MobileCoverage.css'

const MobileCoverage = ({pic, alt, headerText, bodyText}) => {
    const bodyTextElements = bodyText?.map(bodyTextElement =>
        <div className='mobile-coverage-text-description'>
            {bodyTextElement}
        </div>
    )
    return (
        <div className="mobile-coverage">
            {pic ? <div className='mobile-coverage-image-container'>            
                <img src={pic} alt={alt} className="mobile-coverage-image"/>
            </div> : <></>}
            <div className='mobile-coverage-text'>
                <div className='mobile-coverage-text-title'>
                    {headerText}
                </div>
                {bodyTextElements}
            </div>
        </div>
    )
}

export default MobileCoverage;