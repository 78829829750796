import picture from '../assets/images/green-bars.svg'
import Button from './Button/Button';
import SerialNumber from './SerialNumber/SerialNumber';
import SerialNumberInput from './SerialNumberInput/SerialNumberInput';
import { useState } from "react";
import { createSearchParams, useNavigate } from 'react-router-dom';

const Main = () => {
    const [serialNumber, setSerialNumber] = useState("");
    const navigate = useNavigate();

    const handleInput = (data) => {
        setSerialNumber(data);
    }
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());  

    const handleRoute = (serialNumber) => {
        navigate({pathname: `/power/${serialNumber}`, search: `?${createSearchParams(params)}`});
    }

    return (
        <div style={{}}>
            <SerialNumber  headerText={"Stay connected, anywhere."} 
                bodyText={"Activate internet access immediately on this DEFA Power without any additional setup."}
                pic={picture} />
            <SerialNumberInput handler={handleInput}/>
            <Button displayText={"Continue"} buttonAction={() => handleRoute(serialNumber)} showButton={serialNumber.length > 0}/>
        </div>
    )
}

export default Main;